import Request from '@/http'
import { ElMessage } from 'element-plus'

async function useCompanyOptions(filterSetting) {
	try {
		const companyRes = await Request({
			url: 'suf4-user-service/company/select?isPermission=true&status=1',
			method: 'GET',
		})
		if (companyRes.code !== 200) {
			throw new Error(companyRes.msg)
		}
		const filterIndex = filterSetting.value.filters.findIndex(item => {
			return item.attr === 'companyIds'
		})
		if (!companyRes.data) {
			return
		}
		filterSetting.value.filters[filterIndex].options = companyRes.data.map(option => {
			return {
				code: option.companyId,
				name: option.companyName,
			}
		})
		// 获取当前用户的公司Id集合, 用户后续枚举的取用
		const companyIds = companyRes.data.map(option => {
			return option.companyId
		})
		const optionsPromise = Promise.all([
			Request({
				url: `suf4-user-service/userInfo/listByRoleCode/DriverService?isAll=false`,
				method: 'POST',
				data: companyIds,
			}),
			Request({
				url: `suf4-user-service/userInfo/listByRoleCode/Mainten?isAll=false`,
				method: 'POST',
				data: companyIds,
			}),
		])
		const [driverServiceRes, maintenRes] = await optionsPromise
		if (driverServiceRes.code !== 200) {
			throw new Error(driverServiceRes.msg)
		}
		if (maintenRes.code !== 200) {
			throw new Error(maintenRes.msg)
		}
		const driverSupportIdsIndex = filterSetting.value.filters.findIndex(item => {
			return item.attr === 'driverSupportIds'
		})
		if (driverSupportIdsIndex !== -1 && driverServiceRes.data) {
			filterSetting.value.filters[driverSupportIdsIndex].options = driverServiceRes.data.map(
				option => {
					return {
						code: option.id,
						name: option.realName,
					}
				},
			)
		}
		const maintenanceManIdsIndex = filterSetting.value.filters.findIndex(item => {
			return item.attr === 'maintenanceManIds'
		})
		if (maintenanceManIdsIndex !== -1 && maintenRes.data) {
			filterSetting.value.filters[maintenanceManIdsIndex].options = maintenRes.data.map(option => {
				return {
					code: option.id,
					name: option.realName,
				}
			})
		}
	} catch (e) {
		ElMessage.error(e.message)
	}
}

export default useCompanyOptions
