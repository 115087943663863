export const columns = [
	{
		prop: 'modelName',
		label: '车型名称',
		width: 300,
	},
	{
		prop: 'carNo',
		label: '自编号',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
	},
	{
		prop: 'color',
		label: '颜色',
	},
	{
		prop: 'driverName',
		label: '司机名称',
	},
	{
		prop: 'driverPhone',
		label: '司机手机号',
		/* formatter: function (row, column, cellValue, index) {
			return cellValue === 20 ? '上架' : '下架'
		}, */
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		/* formatter: function (row, column, cellValue) {
			return numeral(cellValue).divide(10000).format('0,0.00')
		}, */
	},
	{
		prop: 'carStatus',
		label: '车辆状态',
		formatter: function (row, column, cellValue, index) {
			const carStatusOptions = {
				1: '待整备',
				2: '待维修',
				3: '维修中',
				4: '待出租',
				5: '待交车',
				6: '待退车',
				7: '出租中',
				8: '已报废',
			}
			return carStatusOptions[cellValue]
		},
	},
	{
		prop: 'surrenderDate',
		label: '退租日期',
	},
	{
		prop: 'deferredDays',
		label: '拖延天数',
	},
	{
		prop: 'companyName',
		label: '所属公司',
	},
	{
		prop: 'createTime',
		label: '创建时间',
	},
]

export const filters = {
	expand: true,
	filters: [
		{
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			clearable: true,
			width: 358,
		},
		{
			attr: 'driverSupportIds',
			label: '司服伙伴',
			type: 'multi-select',
			placeholder: '请选择司服伙伴',
		},
		{
			attr: 'deferredDays',
			label: '延期天数',
			value: null,
			type: 'number',
			placeholder: '请输入延期天数',
		},
		{
			attr: 'companyIds',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '请选择所属公司',
		},
	],
}
/*
name: '',
	seriesId: '',
	brandId: '',
	fuelType: '',
	outerColors: '',
	status: '',
	indicativePrice: '',
*/

export const rules = {}
